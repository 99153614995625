
import { ref, computed, onActivated ,onUnmounted} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import navBar from "@/components/navBar.vue";
import { Icon, Field, Form, Button } from "vant";
import { Cell, CellGroup } from "vant";
import { ActionSheet } from "vant";
import { Grid, GridItem } from "vant";
import { Toast, Notify } from "vant";
import { RadioGroup, Radio } from "vant";
import { productBuy, sms, smsCheckcode, nativePay ,productProList} from "@/api/request.js";
import dayjs from "dayjs";
import phone from "@/views/workbench/components/cutNumber.vue";
import axios from "@/api/index.js";
import QRCode from "qrcodejs2"; // 生成二维码
// import $axios from "axios";
import wx from "weixin-js-sdk";
export default {
  name: "order",
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
    navbar: navBar,
    phone: phone,
    [Field.name]: Field,
    [Form.name]: Form,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [ActionSheet.name]: ActionSheet,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },

  setup() {
    const show = ref(false); //选择套餐开关
    const route = useRoute();
    const store = useStore();
    const flag = ref("-1");
    const router = useRouter();
    const price = ref("");
    const name = ref("选择套餐");
    const foo = ref("");
    const setmeal = ref("点击选择套餐");
    const mealList: any = ref([]);
    const phone: any = ref("");
    const mealphone: any = ref(route.query.number);
    const userphone: any = ref("请绑定手机号");
    const phoneText: any = ref("立即绑定");
    const code: any = ref("");
    const scode: any = ref("发送验证码");
    const wxList = ref({});
    const oid = ref("");
    const checked = ref("7");
    const mealdetail: any = ref({});
    const mealShow = ref(false);
    const payshow=ref(false)
    const imgLOgo:any=ref()
    imgLOgo.value=require('../../assets/images/logo.png')
    const codelogo:any =ref(null)
    // const qnumber=ref({})
    // const qid=ref({})
    const off = computed(() => store.state.off);
    const cid = computed(() => store.state.cid);
    // const openid = computed(() => store.state.openid);
    const pattern = ref(
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    );
    console.log(route.query.number, route.query.id, route.query.type, "!!!!!");

    const getvalue = (val: any) => {
      phone.value = val;
    };
    const getconfirm = (val: any) => {
      getsmsCheckcode();
    };

    const disabled = computed(() => {
      return pattern.value.test(phone.value) && scode.value == "发送验证码";
    });

    //  绑定手机号
    const getphone = () => {
      store.commit("getoff", true);
    };
    const getsms = async () => {
      const data = await sms({ phone: phone.value });
      console.log(data);
      //  Notify({ type: "success", message:  });
      Toast({ message: "验证码发送" + data.message, className: "toast" });
    };
    const getsmsCheckcode = async () => {
      const data = await smsCheckcode({
        phone: phone.value,
        code: code.value,
      });
      console.log(data);
      if (data.success == false) {
        Toast({ message: data.message, className: "toast" });
      } else {
        userphone.value = phone.value;
        console.log("aaa");
        phoneText.value = "已绑定";
      }
    };

    // 发送验证码
    const getCodes = () => {
      getsms();
      let num: any = 60;
      var time = setInterval(() => {
        scode.value = (num as string) + "s";
        num as number;
        num--;
        if (num == -1) {
          clearTimeout(time);
          scode.value = "发送验证码";
        }
      }, 1000);
      // Notify({ type: "success", message: 135246 });
    };
    if (route.query.type == "1") {
      oid.value = route.query.oid as any;
      setmeal.value = route.query.productName as any;
      mealdetail.value.madatoryReq = route.query.madatoryReq as any;
      mealdetail.value.fee = route.query.price as any;
    }
    const meal: any = ref(null);
    // 选择套餐
    const getmeal = () => {
      if (route.query.type == "1") {
        meal.value.disabled = true;
      } else if (route.query.type == "2") {
        show.value = true;
        getProductProList();
      }
    };
    const mealArr:any=ref([])
    // 选择套餐
    const getDetail = (index: any, item: any) => {
      flag.value = index;
      mealShow.value = true;
      oid.value = item.oid;
      mealdetail.value = item;
      item.feeType == 1
        ? (price.value = item.fee * 0.01 + "元/年")
        : (price.value = item.fee * 0.01 + "元/月");
      name.value = item.name;
      console.log(item);
      console.log(price.value);
      mealList.value.map((val:any)=>{
        if(item.name==val.name){
          return (mealArr.value[0]=val)
        }
      })
    };
    const getcancel = () => {
      mealdetail.value = {};
      console.log(mealdetail.value);
      flag.value = "-1";
      show.value = false;
    };

    // 确认套餐
    const getconfirm1 = () => {
      console.log(mealdetail.value);
      if (name.value == "选择套餐") {
        Toast("请选择套餐");
      } else {
        show.value = false;
        setmeal.value = price.value + " " + name.value;
      }
    };

    //  // 选择套餐
    const  getproductProList =async()=>{
      const data=await productProList(route.query.id)
      mealList.value = data.data;
        console.log(data, "list");
        if (JSON.stringify(mealList.value) !== "[]") {
          let time = dayjs(mealList.value[0].createTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
    }
    const getProductProList = () => {
      axios(`/product/proList/${route.query.id}?businessId=`, "GET").then((res: any) => {
        mealList.value = res.data;
        console.log(res, "list");
        if (JSON.stringify(mealList.value) !== "[]") {
          let time = dayjs(mealList.value[0].createTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
      });
    };
    getProductProList();
    // getproductProList()
    const openId = computed(() => store.state.openid);
    const codeUrl = ref("");
    console.log(openId.value);
    // 二维码 code
    const success:any=ref(true)
    const GenerateQRcode = async () => {
      const  data  = await nativePay({
        mobile: userphone.value, //手机号码
        number: route.query.number, //95号
        productId: oid.value, //产品id
      });
      console.log(data, "pay", userphone.value, route.query.number, oid.value,data.success);
      codeUrl.value = data.data.codeUrl;
      codelogo.value.src=imgLOgo.value
      success.value=data.success
    };
    const paycode=()=>{
      console.log('code &&&&');
      
      new QRCode("payQrcode", {
        // 此处的qrcode为上面div的id
        text: codeUrl.value,
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
       
      });
    }
    // 购买
    var time:any;
    const getsubmit = async () => {
      if (setmeal.value == "点击选择套餐") {
        Toast("请选择套餐");
      } else if (userphone.value == "请绑定手机号") {
        Toast("请绑定手机号");
      } else {
        if (openId.value == "null") {
          payshow.value=true
         await GenerateQRcode();
        //  注 调用支付转态
          time=  setInterval(() => {
             GenerateQRcode(); //二维码支付
            if(success.value==false){
              payshow.value=false
               console.log('已购买 #￥￥￥');
               
              router.push({
                path: "/purchase/paysuccess",
                query: {
                  price:
                    mealdetail.value.madatoryReq == null
                      ? mealdetail.value.fee * 0.01
                      : mealdetail.value.madatoryReq * 0.01,
                  type: checked.value,
                  show: 1,
                  wx:0
                },
              });
              clearInterval(time)
            }
          },3000);
          paycode()
        } else {
          const data = await productBuy({
            mobile: userphone.value, //手机号码
            productId: oid.value, //套餐id
            number: route.query.number,
            openId: openId.value, //oqZs265Hz9sw5TNJHYFBrPG0iuu4
            cid: "", //当用户购买过站点时传，第一次购买可不传
            payType: checked.value, //7: 微信支付,6 云币
          });
          wxList.value = data.data;
          console.log(data,route.query.id,openId.value);
          // Toast(data.message);
          if (data.success == false) {
            Notify({ type: "danger", message: data.message });
          } else {
            //
          }
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 a
            appId: (wxList.value as any).appId, // 必填，公众号的唯一标识
            timestamp: data.data.timeStamp, // 必填，生成签名的时间戳
            nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
            signature: data.data.paySign, // 必填，签名
            jsApiList: ["chooseWXPay"], // 必填，需要使用的 JS 接口列表
          });
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: ["chooseWXPay"],
              success: function (res: any) {
                console.log("seccess");
                console.log("hskdjskjk", res);
              },
              fail: function () {
                console.log("fail");
              },
            });
          });
          wx.chooseWXPay({
            appid: data.data.appId,
            timestamp: data.data.timeStamp,
            nonceStr: data.data.nonceStr,
            package: data.data.package,
            signType: data.data.signType,
            paySign: data.data.paySign,
            success: function (res: any) {
              console.log(res);

              router.push({
                path: "/purchase/paysuccess",
                query: {
                  price:
                    mealdetail.value.madatoryReq == null
                      ? mealdetail.value.fee * 0.01
                      : mealdetail.value.madatoryReq * 0.01,
                  type: checked.value,
                  show: 1,
                  wx:1
                },
              });
            },
            cancel: function () {
              Toast("已取消支付!");
            },
            fail: function () {
              Toast("支付失败，请重试!");
            },
          });
        }
      }
    };
    const getpayshow=()=>{
      payshow.value=false
      clearInterval(time)
    }
    // 进入页面刷新一次
    const mounted = () => {
      if (location.href.indexOf("#reloaded") == -1) {
        location.href = location.href + "#reloaded";
        location.reload();
      }
    };
    // mounted();
    const onClickLeft = () => {
      router.push({ path: "/purchase/number" });
    };
    onUnmounted(()=>{
      clearInterval(time)
    })
    onActivated(() => {
      // location.reload();
      // const qnumber=ref(route.query.number)
      // const qid=ref(route.query.id)
    });
    return {
      show,
      off,
      scode,
      phone,
      getphone,
      getconfirm,
      userphone,
      phoneText,
      code,
      getCodes,
      getvalue,
      disabled,
      getmeal,
      flag,
      checked,
      getDetail,
      getcancel,
      mealList,
      getsubmit,
      price,
      setmeal,
      mealShow,
      mealdetail,
      name,
      meal,
      mealArr,
      getconfirm1,
      payshow,
      getpayshow,
      mealphone,
      onClickLeft,
      codelogo,
      imgLOgo
    };
  },
};
