
import { ActionSheet, Field, Toast,Icon } from "vant";
import { useStore } from "vuex";
import { ref, computed } from "vue";
export default {
  name: "cut",
  components: {
    [ActionSheet.name]: ActionSheet,
    [Field.name]: Field,
    [Icon.name]:Icon
  },
  props: {
    placeholder: {
      type: String,
      default: "请输入转接号码",
    },
    setnumber: {
      type: String,
      default: "输入号码",
    },
  },
  // 自定义指令
  directives: {
    focus: {
      mounted(el: any) {
        el.focus();
      },
    },
  },
  emits: ["getvalue", "getconfirm", "getclose"],
   // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props: any, emit: any) {
    const store = useStore();
    const value:any = ref('');
    const off = computed(() => store.state.off);
    const pattern = ref(
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    );
    const errorphone = computed(() => {
      return pattern.value.test(value.value);
    });
    const getconfirm = () => {
      if (!errorphone.value) {
        Toast({message:"请输入正确的号码",className:'toast'});
       
      } else {
        store.commit("getoff", false);
        emit.emit("getconfirm", value.value);
        // value.value = "";
      }
      //  store.commit("getPhoneValue",value.value);
    };
    const getclose = () => {
      emit.emit("getclose");
      store.commit("getoff", false);
      value.value = "";
    };
    const getvalue = () => {
      emit.emit("getvalue", value.value);
      // getvalue()
    };
        // 进入页面刷新一次
   const mounted=()=>{
    if (location.href.indexOf("#reloaded") == -1) {
        location.href = location.href + "#reloaded";
        location.reload();
    }
}  ;   
//  mounted()

    return { off, value, errorphone, getconfirm, getclose, getvalue };
  },
};
