import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26cae697"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "cut" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "title_name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_action_sheet = _resolveComponent("van-action-sheet")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_action_sheet, {
      show: $setup.off,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => (($setup.off) = $event)),
      closeable: false,
      "afe-area-inset-bottom": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "cuo",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.getclose && $setup.getclose(...args)))
            }, [
              _createVNode(_component_van_icon, { name: "cross" })
            ]),
            _createElementVNode("div", _hoisted_4, _toDisplayString($props.setnumber), 1),
            _createElementVNode("div", {
              class: "cuo",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.getconfirm && $setup.getconfirm(...args)))
            }, "确定")
          ]),
          _renderSlot(_ctx.$slots, "names", {}, undefined, true),
          _withDirectives(_createVNode(_component_van_field, {
            type: "number",
            modelValue: $setup.value,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => (($setup.value) = $event)),
              $setup.getvalue
            ],
            placeholder: $props.placeholder
          }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"]), [
            [_directive_focus]
          ]),
          _renderSlot(_ctx.$slots, "text", {}, undefined, true)
        ])
      ]),
      _: 3
    }, 8, ["show"])
  ]))
}